.crashpad-layout[layout="A"] .crashpad-accent-1-2,
.crashpad-layout[layout="A"] .crashpad-accent-2-2 {
  display: none;
}
.crashpad-layout[layout="A"] .crashpad-accent-1 {
  top: 0;
  left: -6vh;
  transform: skew(18deg);
}
.crashpad-layout[layout="A"] .crashpad-accent-cover-1 {
  top: 0;
  left: -18vh;
  transform: skew(34deg);
}
.crashpad-layout[layout="A"] .crashpad-accent-2 {
  bottom: 0;
  right: -6vh;
  transform: skew(18deg);
}
.crashpad-layout[layout="A"] .crashpad-accent-cover-2 {
  bottom: 0;
  right: -18vh;
  transform: skew(34deg);
}
